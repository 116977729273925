import {
  AbsoluteCenter,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Skeleton,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useLocation, useNavigation } from 'react-router-dom';
import { LogsSkeleton } from '../../components/CultivationLogs/LogsSkeleton';
import { Scenes, sitemap } from '../sitemap';
import { Loading } from '../../components';

export const RootAuthFallbackElement = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'} gap={2} pt={'64px'}>
      <AbsoluteCenter>
        <Loading size={'lg'} />
      </AbsoluteCenter>
    </Flex>
  );
};

export const AuthFallbackElement = () => {
  return (
    <AbsoluteCenter>
      <Loading fontSize={26} />
    </AbsoluteCenter>
  );
};

export const AuthFallbackElementOld = () => {
  const navigation = useNavigation();
  const locationHook = useLocation();

  const { location: navLocation } = navigation;

  const pathname = navLocation?.pathname || locationHook.pathname;

  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });

  const displayLogs =
    pathname.startsWith(sitemap[Scenes.MY_CULTIVATION].path) ||
    (pathname.startsWith(sitemap[Scenes.DEVICES].path) && pathname.split('/').length === 3); // ensures it only display on /devices/99 not the main /devices route or the children /devices/99/modules/99

  const hasLogs = !isMobile && displayLogs;

  /* this page does not follow the grid layout */
  if (pathname.startsWith(sitemap[Scenes.ANALYTICS].path)) {
    return (
      <Flex direction="column" bg={'appBg'}>
        <AbsoluteCenter>
          <Loading size="lg" />
        </AbsoluteCenter>
      </Flex>
    );
  }

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={3}>
      <GridItem colStart={hasLogs ? undefined : { lg: 3 }} colSpan={{ base: 12, md: 12, lg: 8 }}>
        <Flex direction={'column'} gap={2}>
          <Skeleton width={['50%', '30%']} endColor={'bg500'} startColor="bg100" height={12} borderRadius={'md'} />

          <LoadingContent pathname={pathname} />
        </Flex>
      </GridItem>
      {hasLogs && (
        <GridItem colSpan={{ base: 0, md: 0, lg: 4 }} pt={14}>
          <LogsSkeleton />
        </GridItem>
      )}
    </Grid>
  );
};

interface LoadingContentProps {
  pathname: string;
}
export const LoadingContent: React.FC<LoadingContentProps> = (props) => {
  const { pathname } = props;
  const pathSegments = pathname.split('/').filter(Boolean);

  // Check if we're at the child level (e.g., /my-cultivation/zone/65)
  if (pathname.startsWith(sitemap[Scenes.MY_CULTIVATION].path) && pathSegments.length >= 2) {
    return (
      <Card>
        <CardHeader>
          <Skeleton endColor={'bg500'} startColor="bg100" width={'50ch'} height={12} borderRadius={'md'} />
        </CardHeader>

        <CardBody pt={0}>
          <SimpleGrid minChildWidth={'200px'} gap={4} width={'100%'}>
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
          </SimpleGrid>
        </CardBody>
      </Card>
    );
  }

  // Check if we're on the main "my-cultivation"
  if (pathname.startsWith(sitemap[Scenes.MY_CULTIVATION].path)) {
    return (
      <Card>
        <CardHeader>
          <Skeleton endColor={'bg500'} startColor="bg100" width={'50ch'} height={14} borderRadius={'md'} />
        </CardHeader>
        <CardBody pt={0}>
          <SimpleGrid columns={[1, 2]} gap={4} width={'100%'}>
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
            <Skeleton endColor={'bg500'} startColor="bg100" height={120} borderRadius={'md'} />
          </SimpleGrid>
        </CardBody>
      </Card>
    );
  }

  if (pathname.startsWith(sitemap[Scenes.DEVICES].path)) {
    return (
      <Card>
        <CardBody>
          <SimpleGrid minChildWidth={'210px'} gap={4} width={'100%'}>
            <Skeleton endColor={'bg500'} startColor="bg100" height={90} borderRadius={'md'} />
            <Skeleton endColor={'bg500'} startColor="bg100" height={90} borderRadius={'md'} />
          </SimpleGrid>
        </CardBody>
      </Card>
    );
  }

  return <Skeleton endColor={'bg500'} startColor="bg100" height={'md'} borderRadius={'md'} />;
};

export const SetupFallbackElement = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} py={4} px={0}>
        <Flex direction={'column'} gap={2}>
          <Card>
            <CardHeader>
              <Skeleton width={'200px'} height={12} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
            </CardHeader>
            <CardBody>
              <VStack spacing={12} alignItems={'start'} width={'100%'}>
                <Flex direction={'column'} width={'100%'} gap={4}>
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={16} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100%'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
                <Flex direction={'row'} width={'100%'} justifyContent={'space-between'} gap={4} my={4}>
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                  <Skeleton width={'100px'} height={10} endColor={'bg500'} startColor="bg100" borderRadius={'md'} />
                </Flex>
              </VStack>
            </CardBody>
          </Card>
        </Flex>
      </Container>
    </Flex>
  );
};
