import React from 'react';
import { AbsoluteCenter, Container, Flex, Spacer } from '@chakra-ui/react';
import { Header } from './Header';
import { Navigate, Outlet, useNavigation } from 'react-router-dom';
import { Footer } from './Footer';
import { BottomToTopAnimation, Loading } from '..';
import { useSessionPermissions } from '../../session/useSessionPermissions';
import { Role } from 'common';
import { Scenes, sitemap } from '../../routing';
import { MQTTClientProvider } from '../../broker/MQTTClientContext';
import { RealTimeListenerProvider } from '../../broker/RealTimeListenerContext';
import { JobExecutionProvider } from '../Jobs/JobExecutionContext';
import { AuthFallbackElement, SetupFallbackElement } from '../../routing/router/FallbackElement';

export const UnauthTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Container maxW={'lg'} py={4} flexGrow={1} alignContent={'center'}>
        {state === 'loading' ? (
          <Flex direction="column" minH="100vh" bg={'appBg'}>
            <AbsoluteCenter>
              <Loading size="lg" />
            </AbsoluteCenter>
          </Flex>
        ) : (
          <Outlet />
        )}
      </Container>
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const SetupTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'lg'} px={0} py={4}>
        <JobExecutionProvider>{state === 'loading' ? <SetupFallbackElement /> : <Outlet />}</JobExecutionProvider>
      </Container>
      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={2} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const FullAuthTemplate: React.FC = () => {
  const { state } = useNavigation();

  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'container.xl'} px={{ base: 2, md: 4 }}>
        <MQTTClientProvider>
          <RealTimeListenerProvider>
            <JobExecutionProvider>{state === 'loading' ? <AuthFallbackElement /> : <Outlet />}</JobExecutionProvider>
          </RealTimeListenerProvider>
        </MQTTClientProvider>
      </Container>

      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={2} />
      </BottomToTopAnimation>
    </Flex>
  );
};

export const AdminTemplate: React.FC = () => {
  const permissions = useSessionPermissions();

  if (!permissions.hasRole(Role.OWNER)) {
    return <Navigate to={sitemap[Scenes.MY_CULTIVATION].path} />;
  }

  return <FullAuthTemplate />;
};

export const SmallAuthTemplate: React.FC = () => {
  return (
    <Flex direction="column" minH="100dvh" bg={'appBg'}>
      <Header isSidebarVisible />
      <Container maxW={'lg'} mx="auto" px={0} py={2}>
        <Outlet />
      </Container>
      <Spacer />
      <BottomToTopAnimation>
        <Footer width={'full'} zIndex={'docked'} mt={2} />
      </BottomToTopAnimation>
    </Flex>
  );
};
