import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { API } from './trpc';
import { useSession } from '../session/useSession';
import { getEnvs } from '../utils/getEnvs';
import { tokenRefreshLink } from 'trpc-token-refresh-link';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:APIProviver');
interface APIProvicerProps {
  children: React.ReactNode;
}

export const APIProviver: React.FC<APIProvicerProps> = (props) => {
  const { release, apiBaseURL } = getEnvs();

  const url = release === 'local' ? `http://localhost:8080/api` : `${apiBaseURL}/api`;

  const { isLoggedIn, session, refresh } = useSession();

  const onError = (error: unknown) => {
    debug('onError', { error });
  };

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError,
            retry: 1,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
          },
          mutations: {
            onError,
            retry: 1,
          },
        },
      }),
    []
  );

  const trpcClient = React.useMemo(() => {
    if (isLoggedIn) {
      const idToken = session.getIdToken();
      const jwtToken = idToken.getJwtToken();
      const headers = {
        authorization: `Bearer ${jwtToken}`,
      };

      return API.createClient({
        links: [
          tokenRefreshLink({
            tokenRefreshNeeded: () => {
              const expiration = idToken.getExpiration();

              // refresh 30 seconds before expiration
              const refreshTime = new Date(expiration * 1000 - 30000);
              if (refreshTime.getTime() < Date.now()) {
                return true;
              }
              return false;
            },
            fetchAccessToken: async () => {
              try {
                const newSession = await refresh();
                debug('refreshed by link');
                const newJWTToken = newSession.getIdToken().getJwtToken();
                headers.authorization = `Bearer ${newJWTToken}`;
              } catch (error: unknown) {
                window.location.reload();
              }
            },
          }),
          httpLink({
            url,
            headers,
          }),
        ],
      });
    }

    return API.createClient({
      links: [
        httpLink({
          url,
        }),
      ],
    });
  }, [session, isLoggedIn, url, refresh]);

  return (
    <API.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </API.Provider>
  );
};
