import { NavigateProps, Navigate, To } from 'react-router-dom';
import { useQueryParams } from '../utils/useQueryParams';
import { useSessionUser } from '../session/useSessionUser';
import { Scenes, sitemap } from './sitemap';
import { usePendingSetupDevice } from '../components/Devices/usePendingSetupDevice';

export const NavigteWithQueryParams = (props: NavigateProps) => {
  const queryParams = useQueryParams();

  if (typeof props.to === 'string') {
    return <Navigate {...props} to={{ pathname: props.to, search: queryParams.toString() }} />;
  }

  return <Navigate {...props} to={{ ...props.to, search: queryParams.toString() }} />;
};

export const NavigateInvite = () => {
  const user = useSessionUser();

  const isInvite = !!user?.invite;

  if (isInvite) {
    return <NavigteWithQueryParams to={sitemap[Scenes.SETUP].children.invite.path} />;
  }

  return <NavigteWithQueryParams to={sitemap[Scenes.SETUP].path} />;
};

interface NavigatePendingDeviceProps {
  defaultRoute: To;
}
export const NavigatePendingDevice = (props: NavigatePendingDeviceProps) => {
  const { defaultRoute } = props;
  const { pendingDeviceId } = usePendingSetupDevice();

  if (pendingDeviceId !== null) {
    const path = sitemap[Scenes.DEVICES].children.devices.buildPath(String(pendingDeviceId));

    return <NavigteWithQueryParams to={path} />;
  }

  return <NavigteWithQueryParams to={defaultRoute} />;
};
