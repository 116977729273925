import React from 'react';
import { IntlProvider } from 'react-intl';
import { getBrowserLocale, DEFAULT_LOCALE, Locales, isSupportedLocale } from './utils';
import { useSessionUser } from '../session/useSessionUser';
import { enUS, es, ptBR } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { assertUnreachable } from 'common';
import createDebugger from 'debug';

const debug = createDebugger('I18N:TranslationProvider');
interface Props {
  children?: React.ReactNode;
}

const useLocale = () => {
  try {
    const user = useSessionUser();
    return user.locale;
  } catch (e) {
    return null;
  }
};
export const TranslationProvider: React.FC<Props> = (props) => {
  const BROWSER_LOCALE = getBrowserLocale();

  const locale = useLocale();

  React.useEffect(() => {
    if (isSupportedLocale(locale ?? '')) {
      switch (locale) {
        case Locales.EN_US:
          registerLocale(locale, enUS); // eslint-disable-line
          break;
        case Locales.ES_AR:
          registerLocale(locale, es); // eslint-disable-line
          break;
        case Locales.PT_BR:
          registerLocale(locale, ptBR); // eslint-disable-line
          break;
        default:
          assertUnreachable('Invalid locale', locale as never);
      }
    }
  }, [locale]);
  return (
    <IntlProvider
      locale={locale ?? BROWSER_LOCALE}
      defaultLocale={DEFAULT_LOCALE}
      messages={{}}
      onError={(e) => {
        debug(e);
      }}
    >
      {props.children}
    </IntlProvider>
  );
};
