import { SessionUser, parseCognitoPayload } from 'common';
import { AuthenticatedSession } from './session';
import { useSession } from './useSession';

export const useSessionUser = (): SessionUser => {
  const { session } = useSession<AuthenticatedSession>();

  const idToken = session.getIdToken();
  const payload = idToken.decodePayload();
  const user = parseCognitoPayload(payload);

  return user;
};
