import { SetupRouter } from './router/SetupRouter';
import { AuthRouter } from './router/AuthRouter';
import { UnauthRouter } from './router/UnauthRouter';
import { useSessionUser } from '../session/useSessionUser';
import { useSessionPermissions } from '../session/useSessionPermissions';
import { useSession } from '../session/useSession';

/**
 * Users can be authenticated or not.
 *
 * Authenticated users may still need to complete the setup
 * process.
 * So, the routing will depend on the status of their account
 *
 * In other case, they will be redirected
 * to the unauth scene
 */

const Auth = () => {
  const user = useSessionUser();
  const permissions = useSessionPermissions();
  const organizations = permissions.listOrganizations();

  const isSetupPending = organizations.length === 0 || !!user?.invite;

  if (isSetupPending) {
    return <SetupRouter />;
  }

  return <AuthRouter />;
};

export const AppRouter = () => {
  const { isLoggedIn } = useSession();

  if (!isLoggedIn) {
    return <UnauthRouter />;
  }

  return <Auth />;
};
