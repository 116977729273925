import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Scenes, sitemap } from '../sitemap';
import { UnauthTemplate } from '../../components/Layout/Template';
import { useSession } from '../../session/useSession';
import { AbsoluteCenter, Flex } from '@chakra-ui/react';
import { Loading } from '../../components';
import { NavigteWithQueryParams } from '../utils';

// we don't need to manage any routing error in this scene, just redirect to login
const router = createBrowserRouter([
  {
    path: sitemap[Scenes.UNAUTH].path,
    element: <UnauthTemplate />,
    errorElement: <Navigate to={sitemap[Scenes.UNAUTH].children.signIn.path} />,
    children: [
      {
        path: sitemap[Scenes.UNAUTH].children.signIn.path,
        lazy: () => import('../../scenes/Unauth/SignIn'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.signUp.path,
        lazy: () => import('../../scenes/Unauth/SignUp'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.forgotPassword.path,
        lazy: () => import('../../scenes/Unauth/ForgotPassword'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.passwordRestore.path,
        lazy: () => import('../../scenes/Unauth/PasswordRestore'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.mfa.path,
        lazy: () => import('../../scenes/Unauth/MFAuthentication'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.passwordRestore.path,
        lazy: () => import('../../scenes/Unauth/PasswordRestore'),
      },
      {
        path: sitemap[Scenes.UNAUTH].children.signupConfirmation.path,
        lazy: () => import('../../scenes/Unauth/SignUpConfirm'),
      },
      {
        path: '*',
        element: <NavigteWithQueryParams to={sitemap[Scenes.UNAUTH].path} />,
      },
    ],
  },
  {
    index: true,
    element: <Navigate to={sitemap[Scenes.UNAUTH].children.signIn.path} />,
  },
]);

export const UnauthRouter: React.FC = () => {
  const { isBooting } = useSession();

  if (isBooting) {
    return (
      <Flex direction="column" minH="100vh" bg={'appBg'}>
        <AbsoluteCenter>
          <Loading size="lg" />
        </AbsoluteCenter>
      </Flex>
    );
  }

  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <Flex direction="column" minH="100vh" bg={'appBg'}>
          <AbsoluteCenter>
            <Loading size="lg" />
          </AbsoluteCenter>
        </Flex>
      }
    />
  );
};
