import parsePhoneNumber, { isValidPhoneNumber as isValidPhoneNumberLib, CountryCode, PhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

export const USERNAME_REGEX = /^[\p{L}\p{M}\p{S}\p{N}\p{P}\S*]+$/u;

/**
 * Validations
 */
export const isValidEmail = (value: string) => {
  const result = z.string().email().safeParse(value);

  return result.success;
};

export const usernameSchema = z.string().regex(USERNAME_REGEX);

export const isValidUsername = (value: string): boolean => {
  return usernameSchema.safeParse(value).success;
};

export const sanitizePhone = (value: string, country?: string): string | null => {
  const result = parsePhoneNumber(`${value}`, country as CountryCode);

  if (!result) {
    return null;
  }

  return result.format('E.164');
};

export const parsePhone = (phone: string): PhoneNumber | null => {
  const result = parsePhoneNumber(phone);

  return result ?? null;
};

export const isValidPhoneNumber = (value: string, country?: string): boolean => {
  return isValidPhoneNumberLib(`${value}`, country as CountryCode);
};

export const macAddressSchema = z.string().regex(MAC_ADDRESS_REGEX);

export const isValidMACAddress = (value: string): boolean => {
  return macAddressSchema.safeParse(value).success;
};

/**
 * assertions
 */
export function validateNotNil<T>(value: T | null | undefined): asserts value is T {
  if (value === null || value === undefined) {
    throw new TypeError('Value is nil');
  }
}

export function validateString(value: unknown): asserts value is string {
  if (!z.string().safeParse(value).success) {
    throw new Error('Value is not a string');
  }
}

export function validateNumber(value: unknown): asserts value is number {
  validateNotNil(value);

  if (!z.number().safeParse(value).success) {
    throw new Error('Value is not a number');
  }
}

export function validateDate(value: unknown): asserts value is Date {
  validateNotNil(value);

  if (!z.date().safeParse(value).success) {
    throw new Error('Value is not a date');
  }
}

export function validateArray(value: unknown): asserts value is Array<unknown> {
  validateNotNil<unknown>(value);

  if (!z.array(z.any()).safeParse(value).success) {
    throw new Error('Value is not an array');
  }
}

export function validateNonEmptyObject(value: unknown): asserts value is Record<string, unknown> {
  validateNotNil(value);

  if (typeof value !== 'object') {
    throw new TypeError('Value is not an object');
  }

  if (Object.keys(value as object).length === 0) {
    throw new TypeError('Value is an empty object');
  }
}

export function validateObjectKey<T extends object>(obj: T, key: string): asserts obj is T {
  validateNonEmptyObject(obj);

  if (!(key in obj)) {
    throw new TypeError(`Missing key ${String(key)}`);
  }
}

export const validateEmail = (value: string) => {
  if (!isValidEmail(value)) {
    throw new TypeError('Invalid e-mail address');
  }
};

export const validatePhoneNumber = (value: string) => {
  if (!isValidPhoneNumber(value)) {
    throw new TypeError('Invalid phone number');
  }
};

export const validateMACAddress = (value: unknown): asserts value is string => {
  validateString(value);

  if (!macAddressSchema.safeParse(value).success) {
    throw new Error('Value is not a MAC Address');
  }
};

/**
 * AWS Region
 */
// we need this to validate envs
export type Region =
  | 'us-east-1'
  | 'us-east-2'
  | 'us-gov-east-1'
  | 'us-gov-west-1'
  | 'us-iso-east-1'
  | 'us-iso-west-1'
  | 'us-isob-east-1'
  | 'us-west-1'
  | 'us-west-2'
  | 'af-south-1'
  | 'ap-east-1'
  | 'ap-northeast-1'
  | 'ap-northeast-2'
  | 'ap-northeast-3'
  | 'ap-south-1'
  | 'ap-south-2'
  | 'ap-southeast-1'
  | 'ap-southeast-2'
  | 'ap-southeast-3'
  | 'ap-southeast-4'
  | 'ca-central-1'
  | 'cn-north-1'
  | 'cn-northwest-1'
  | 'eu-central-1'
  | 'eu-central-2'
  | 'eu-north-1'
  | 'eu-south-1'
  | 'eu-south-2'
  | 'eu-west-1'
  | 'eu-west-2'
  | 'eu-west-3'
  | 'il-central-1'
  | 'me-central-1'
  | 'me-south-1'
  | 'sa-east-1';

const validRegion = [
  'us-east-1',
  'us-east-2',
  'us-gov-east-1',
  'us-gov-west-1',
  'us-iso-east-1',
  'us-iso-west-1',
  'us-isob-east-1',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-south-1',
  'ap-south-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-central-2',
  'eu-north-1',
  'eu-south-1',
  'eu-south-2',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'il-central-1',
  'me-central-1',
  'me-south-1',
  'sa-east-1',
];

// This validates that the string input is a valid AWS region
export function validateRegion(region: string): asserts region is Region {
  if (!Object.values(validRegion).includes(region)) {
    throw new Error(`Invalid region: ${region}`);
  }
}
/**
 * Use this in your `switch` default cases so typescript yells
 * when you miss a possible case. Example:
 *
 * switch(case){
 *   case Enum.foo:
 *      ...
 *   case Enum.bar:
 *      ...
 *   default:
 *      assertUnreachable('Invalida case', case)
 * }
 *
 */
export function assertUnreachable(message: string, value: never): never {
  throw new Error(`${message} ${value as string}`);
}
