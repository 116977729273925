import { validateNonEmptyObject, validateObjectKey, validateString } from './validations';

// roles and mapping to shorter values
export enum Role {
  OWNER = 'owner',
  GROWER = 'grower',
  OPERATOR = 'operator',
  TRANSLATOR = 'translator',
}

export enum ShortRole {
  OWNER = 'o',
  GROWER = 'g',
  OPERATOR = 'op',
  TRANSLATOR = 'T',
}

export const roleColorMap: Record<Role, string> = {
  [Role.OWNER]: 'administratorRole',
  [Role.GROWER]: 'growerRole',
  [Role.OPERATOR]: 'operatorRole',
  [Role.TRANSLATOR]: 'translatorRole',
};

const roleMapping: Record<Role, ShortRole> = {
  [Role.OWNER]: ShortRole.OWNER,
  [Role.GROWER]: ShortRole.GROWER,
  [Role.OPERATOR]: ShortRole.OPERATOR,
  [Role.TRANSLATOR]: ShortRole.TRANSLATOR,
};

const reverseRoleMapping: Record<ShortRole, Role> = {
  [ShortRole.OWNER]: Role.OWNER,
  [ShortRole.GROWER]: Role.GROWER,
  [ShortRole.OPERATOR]: Role.OPERATOR,
  [ShortRole.TRANSLATOR]: Role.TRANSLATOR,
};

// internal permissions type and mapping to shorter values
export enum InternalPermissionsType {
  DEVICE = 'device',
  ZONE = 'zone',
}

enum ShortInternalPermissionsType {
  DEVICE = 'd',
  ZONE = 'z',
}

const internalPermissionsTypeMapping: Record<InternalPermissionsType, ShortInternalPermissionsType> = {
  [InternalPermissionsType.DEVICE]: ShortInternalPermissionsType.DEVICE,
  [InternalPermissionsType.ZONE]: ShortInternalPermissionsType.ZONE,
};

const reverseInternalPermissionsTypeMapping: Record<ShortInternalPermissionsType, InternalPermissionsType> = {
  [ShortInternalPermissionsType.DEVICE]: InternalPermissionsType.DEVICE,
  [ShortInternalPermissionsType.ZONE]: InternalPermissionsType.ZONE,
};

/**
 * Permissions scope:
 *
 * Global: roles apply for all organizations
 * Organization: in this case roles only apply to the corresponding organization
 * Internal: roles apply to the whole device or zone depending on the type
 */
export enum Scope {
  GLOBAL = 'global',
  ORGANIZATION = 'organization',
  INTERNAL = 'internal', // it means the user can have permissions over a specific DEVICE or ZONE
}

export function isValidRole(value: unknown): value is Role {
  return typeof value === 'string' && Object.values(Role).includes(value as Role);
}

export function isPartialOrganizationPermission(permissions: OrganizationPermissions): permissions is PartialOrganizationPermission {
  return 'role' in permissions;
}

export function isInternalZonePermissions(value: unknown): value is Array<InternalZonePermission> {
  return (
    Array.isArray(value) &&
    value.every(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        'id' in item &&
        'type' in item &&
        'role' in item &&
        (item as InternalZonePermission).type === InternalPermissionsType.ZONE &&
        isValidRole((item as InternalZonePermission).role)
    )
  );
}

export function isInternalZonePermission(value: unknown): value is InternalZonePermission {
  return (
    typeof value === 'object' &&
    value !== null &&
    'id' in value &&
    'type' in value &&
    'role' in value &&
    (value as InternalZonePermission).type === InternalPermissionsType.ZONE &&
    isValidRole((value as InternalZonePermission).role)
  );
}

export function validateRole(value: unknown): asserts value is Role {
  validateString(value);

  if (!Object.values(Role).includes(value as Role)) {
    throw new Error(`Invalid role: ${value}`);
  }
}

export function validateShortRole(value: unknown): asserts value is ShortRole {
  validateString(value);

  if (!Object.values(ShortRole).includes(value as ShortRole)) {
    throw new Error(`Invalid ShortRole: ${value}`);
  }
}

export function validateInternalPermissionsType(value: unknown): asserts value is InternalPermissionsType {
  validateString(value);

  if (!Object.values(InternalPermissionsType).includes(value as InternalPermissionsType)) {
    throw new Error(`Invalid InternalPermissionsType: ${value}`);
  }
}

export function validateShortInternalPermissionsType(value: unknown): asserts value is ShortInternalPermissionsType {
  validateString(value);

  if (!Object.values(ShortInternalPermissionsType).includes(value as ShortInternalPermissionsType)) {
    throw new Error(`Invalid ShortInternalPermissionsType: ${value}`);
  }
}

interface InternalPermissionsReference {
  id: number;
  type: InternalPermissionsType;
}
export interface InternalPermissions extends InternalPermissionsReference {
  role: Role; // over the entire zone or device depending on type
}

export interface PartialOrganizationPermission {
  id: number;
  role: Role; // over the entire organization
  internal?: Array<InternalPermissions>;
}

export interface PartialInternalPermission {
  id: number;
  internal: Array<InternalPermissions>;
}
export type OrganizationPermissions = PartialOrganizationPermission | PartialInternalPermission;

export interface PermissionsObject {
  roles: Set<Role>; // global roles
  organizations: Array<OrganizationPermissions>;
}

export interface InternalZonePermission {
  id: number;
  type: InternalPermissionsType.ZONE;
  role: Role;
}

export interface InternalDevicePermission {
  id: number;
  type: InternalPermissionsType.DEVICE;
  role: Role;
}

type ShortInternalPermissions = { r: ShortRole; t: ShortInternalPermissionsType };
type ShortOrganizationPermissions = Record<string, ShortRole | ShortInternalPermissions>;

export type ShortPermissions = Record<string, Array<ShortRole> | ShortOrganizationPermissions>;

export class Permission {
  permissions: PermissionsObject;

  constructor(permissionData: string | ShortPermissions | null) {
    if (permissionData) {
      const data = typeof permissionData === 'string' ? (JSON.parse(permissionData) as unknown) : permissionData;
      this.permissions = this.parsePermissions(data);
    } else {
      this.permissions = this.parsePermissions({ r: new Set() });
    }
  }

  private parsePermissions(data: unknown): PermissionsObject {
    validateNonEmptyObject(data);

    const organizations: Array<OrganizationPermissions> = Object.entries(data)
      .filter(([organizationId]) => organizationId !== 'r')
      .map(([organizationId, _organization]) => {
        validateNonEmptyObject(_organization);

        const internal = Object.entries(_organization)
          .filter(([internalReferenceId]) => internalReferenceId !== 'r')
          .map(([internalReferenceId, _internalReference]) => {
            validateNonEmptyObject(_internalReference);
            validateObjectKey(_internalReference, 'r');
            validateObjectKey(_internalReference, 't');

            const { r: _internalReferenceRole, t: _internalReferenceType } = _internalReference;

            // this is temporary to use legacy internal permissions
            if (this.isValidShortRoleList(_internalReferenceRole)) {
              validateShortInternalPermissionsType(_internalReferenceType);

              return {
                id: Number(internalReferenceId),
                role: reverseRoleMapping[_internalReferenceRole[0]],
                type: reverseInternalPermissionsTypeMapping[_internalReferenceType],
              };
            }

            validateShortRole(_internalReferenceRole);
            validateShortInternalPermissionsType(_internalReferenceType);

            const internalPermissionsType = reverseInternalPermissionsTypeMapping[_internalReferenceType];
            const internalReferenceRole = reverseRoleMapping[_internalReferenceRole];

            return {
              id: Number(internalReferenceId),
              role: internalReferenceRole,
              type: internalPermissionsType,
            };
          });

        if (this.isValidShortRole(_organization.r)) {
          const organizationPermission: PartialOrganizationPermission = {
            id: Number(organizationId),
            role: reverseRoleMapping[_organization.r],
            internal,
          };

          return organizationPermission;
        }

        // this is temporary to use legacy permissions
        const tempOrganizationRole = _organization.r;

        if (this.isValidShortRoleList(tempOrganizationRole)) {
          const organizationPermission: PartialOrganizationPermission = {
            id: Number(organizationId),
            role: reverseRoleMapping[tempOrganizationRole[0]],
            internal,
          };

          return organizationPermission;
        }

        const internalPermission: PartialInternalPermission = { id: Number(organizationId), internal };

        return internalPermission;
      });

    const globalRoles = this.isValidShortRoleList(data.r)
      ? new Set(data.r.map((shortRole) => reverseRoleMapping[shortRole]))
      : new Set<Role>();

    const permissions: PermissionsObject = {
      roles: globalRoles,
      organizations,
    };

    return permissions;
  }

  private isValidShortRole = (value: unknown): value is ShortRole => {
    return typeof value === 'string' && Object.values(ShortRole).includes(value as ShortRole);
  };

  private isValidShortRoleList = (value: unknown): value is Array<ShortRole> => {
    return Array.isArray(value) && value.every(this.isValidShortRole);
  };

  serialize(): string {
    const shortPermissions = this.shortify();
    if (Object.keys(shortPermissions).length === 0) return '';

    return JSON.stringify(shortPermissions);
  }

  shortify(): ShortPermissions {
    const shortPermissions: ShortPermissions = {};

    // global roles
    if (this.permissions.roles.size > 0) {
      shortPermissions.r = Array.from(this.permissions.roles).map((role) => roleMapping[role]);
    }

    // organization roles
    this.permissions.organizations.forEach((organization) => {
      const shortOrganizationPermissions: ShortOrganizationPermissions = {};

      if ('role' in organization) {
        shortOrganizationPermissions.r = roleMapping[organization.role];
      }

      if (organization.internal) {
        organization.internal.forEach((internal) => {
          const shortInternalPermissions: ShortInternalPermissions = {
            r: roleMapping[internal.role],
            t: internalPermissionsTypeMapping[internal.type],
          };

          shortOrganizationPermissions[internal.id] = shortInternalPermissions;
        });
      }

      shortPermissions[organization.id] = shortOrganizationPermissions;
    });

    return shortPermissions;
  }

  hasAnyRoleInOrganization(organizationId: number) {
    // Check global roles first - only the global role OWNER grants this permission
    if (this.permissions.roles.has(Role.OWNER)) {
      return true;
    }

    return Object.values(Role).some((role) => {
      // Find the organization by ID
      const organization = this.permissions.organizations.find((o) => o.id === organizationId);

      if (organization !== undefined && 'role' in organization && organization?.role === role) {
        return true;
      }

      return false;
    });
  }

  hasAnyInternalRole(organizationId: number, type: InternalPermissionsType, internalId: number) {
    // Check global roles first - only the global role OWNER grants this permission
    if (this.permissions.roles.has(Role.OWNER)) {
      return true;
    }

    return Object.values(Role).some((role) => {
      // Find the organization by ID
      const organization = this.permissions.organizations.find((o) => o.id === organizationId);

      if (organization === undefined) {
        return false;
      }

      if ('role' in organization && organization?.role === role) {
        return true;
      }

      // Find the device by ID
      if (organization.internal) {
        const internal = organization?.internal.find((i) => i.type === type && i.id === internalId);
        if (internal?.role === role) {
          return true;
        }
      }

      return false;
    });
  }

  /**
   * Check if a role exists at various levels.
   * Bigger scopes inherently include smaller scopes within them.
   * For example, having a role at the organization level means having the role for all devices and IOs inside it.
   */
  hasRole(role: Role, organizationId?: number, internalReference?: InternalPermissionsReference): boolean {
    // Check global roles first
    if (this.permissions.roles.has(role)) {
      return true;
    }

    // If no organizationId is provided, the role does not exist in the narrower scopes
    if (!organizationId) return false;

    // Find the organization by ID
    const organization = this.permissions.organizations.find((o) => o.id === organizationId);

    // No organization means no permissions
    if (!organization) return false;

    // If the role exists at the organization level, it's valid for all devices and IOs within
    if ('role' in organization && organization.role === role) {
      return true;
    }

    // If no internal reference is provided or found, the role does not exist in the narrower scope (IOs)
    if (!internalReference) return false;

    if (organization.internal) {
      // Find the device by ID
      const internal = organization.internal.find((i) => i.type === internalReference.type && i.id === internalReference.id);

      // No device means no permissions
      if (!internal) return false;

      // If the role exists at the internal reference level, it's valid for all IOs within
      return internal.role === role;
    } else {
      return false;
    }
  }

  addGlobalRole(role: Role) {
    this.permissions.roles.add(role);
  }

  removeGlobalRole(role: Role) {
    // maybe this should prevent leaving empty permissions obj, no global or organization
    this.permissions.roles.delete(role);
  }

  setOrganizationPermissions(newOrganizationPermissions: OrganizationPermissions, organizationId: number) {
    const organizationPermissionsIndex = this.permissions.organizations.findIndex((o) => o.id === organizationId);

    if (organizationPermissionsIndex >= 0) {
      this.permissions.organizations[organizationPermissionsIndex] = newOrganizationPermissions;
    } else {
      this.permissions.organizations.push(newOrganizationPermissions);
    }
  }

  static createInternalZonePermission(role: Role, id: number): InternalZonePermission {
    return {
      id,
      role,
      type: InternalPermissionsType.ZONE,
    };
  }

  static createInternalDevicePermission(role: Role, id: number): InternalDevicePermission {
    return {
      id,
      role,
      type: InternalPermissionsType.DEVICE,
    };
  }

  static createOrganizationInternalPemissions(internalPermissions: Array<InternalPermissions>, id: number): PartialInternalPermission {
    return {
      id,
      internal: internalPermissions,
    };
  }

  static createOrganizationPermission(
    role: Role,
    id: number,
    internalPermissions?: Array<InternalPermissions>
  ): PartialOrganizationPermission {
    return {
      id,
      role,
      internal: internalPermissions,
    };
  }

  listDevices(organizationId: number): Array<number> {
    const organization = this.permissions.organizations.find((o) => o.id === organizationId);
    if (!organization || !organization.internal) return [];

    return organization.internal.filter((i) => i.type === InternalPermissionsType.DEVICE).map((d) => d.id);
  }

  listZones(organizationId: number): Array<number> {
    const organization = this.permissions.organizations.find((o) => o.id === organizationId);
    if (!organization || !organization.internal) return [];

    return organization.internal.filter((i) => i.type === InternalPermissionsType.ZONE).map((z) => z.id);
  }

  listOrganizations(): Array<number> {
    return this.permissions.organizations.map((o) => o.id);
  }

  removeOrganization(organizationId: number) {
    const newOrganizations = this.permissions.organizations.filter((o) => o.id !== organizationId);

    this.permissions.organizations = newOrganizations;
  }
}
